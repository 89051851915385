<template>
  <div data-test="customer-search">
    <VAutocomplete
      ref="autocomplete"
      placeholder="Sök efter Kund-nr, Kund-namn, Adress, Adress-gln"
      :value="modelValue"
      :options="recipients"
      :has-validation-error="v$.modelValue.$error"
      @query-changed="findRecipients"
      @option-selected="emitSelection"
    >
      <template #list-item="{item, query}">
        <!-- eslint-disable vue/no-v-html -->
        <div class="fs-15 fw-bold">
          <span v-html="highlightSearchResult(item.customer.number, query, true)" /> -
          <span v-html="highlightSearchResult(item.customer.name, query, true)" />
        </div>
        <div class="fs-14">
          <div v-if="item.customer.gln">
            GLN: <span v-html="highlightSearchResult(item.customer.gln, query, true)" />
          </div>
          <div>
            <span v-html="highlightSearchResult(item.address.street_address, query, true)" />
          </div>
          <div>
            {{ item.address.zip }} <span v-html="highlightSearchResult(item.address.city.toUpperCase(), query, true)" />
          </div>
          <div v-if="item.address.gln">
            GLN: <span v-html="highlightSearchResult(item.address.gln, query, true)" />
          </div>
        </div>
        <!-- eslint-enable vue/no-v-html -->
      </template>

      <template #selected-item="{item}">
        <div>
          <div class="fs-15 fw-bold">
            {{ item.customer.number }} - {{ item.customer.name }}
          </div>
          <div class="fs-14">
            {{ item.address.street_address }}, {{ item.address.zip }} {{ item.address.city.toUpperCase() }}
          </div>
          <div
            v-if="item.address.gln"
            class="fs-14"
          >
            GLN: {{ item.address.gln }}
          </div>
        </div>
      </template>
    </VAutocomplete>
    <InputErrorDisplay :errors="v$.modelValue.$errors" />
  </div>
</template>

<script>
import VAutocomplete from '@/components/VAutocomplete.vue'
import InputErrorDisplay from '@/components/InputErrorDisplay.vue'
import debounce from '@/components/mixins/debounce.js'
import highlightSearchResult from '@/components/mixins/highlightSearchResult.js'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

export default {
  components: {
    VAutocomplete,
    InputErrorDisplay,
  },
  mixins: [debounce, highlightSearchResult],
  inject: ['axios'],
  props:  {
    modelValue: {
      type:    Object,
      default: () => {},
    },
  },
  emits: ['update:modelValue'],
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      modelValue: {
        required,
      },
    }
  },
  data () {
    return {
      recipients: [],
    }
  },
  methods: {
    findRecipients (query) {
      this.debounce(async () => {
        const response = await this.axios.get('/internal_api/recipients?filter=' + query)
        this.recipients = response.data.recipients
      }, 250)()
    },
    emitSelection (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    focusInput () {
      this.$refs.autocomplete.focusInput()
    },
  },
}
</script>

<style scoped lang="scss">
</style>
