<template>
  <button
    class="btn btn-outline-dark mx-3"
    :disabled="newOrders.count === 0"
    @click="click"
  >
    {{ newOrders.count }} inkomna säljordrar
  </button>
</template>

<script>
import { reactive, watch } from 'vue'
import { useActionCable } from '@/composables/useActionCable.js'

export default {
  props: {
    locationId: {
      type:     Number,
      required: true,
    },
  },
  emits: ['fetch-sales-orders'],
  setup (props) {
    const { cableCreateSubscription } = useActionCable()
    const newOrders = reactive({ count: 0 })

    cableCreateSubscription(
      {
        channel:     'NewSalesOrderChannel',
        location_id: props.locationId,
      },
      {
        received (data) {
          const newCount = (newOrders.count + data.body)
          watch(() => {
            newOrders.count = newCount
          })
        },
      },
    )

    return {
      newOrders,
    }
  },
  methods: {
    click () {
      this.$emit('fetch-sales-orders')
      this.newOrders.count = 0
    },
  },
}
</script>

<style scoped lang="scss">
</style>
